<!--
 * @Author: Dyf
 * @LastEditors: wang hai yang
 * @Date: 2023-03-13 09:24:13
 * @LastEditTime: 2023-05-12 14:02:43
 * @Descripttion: 文章详情
-->
<style lang="scss" scoped>
.article-detail {
    position: relative;
    @include innerPage(40px 0 48px);

    .el-button {
        position: absolute;
        bottom: 30px;
        left: 30px;
    }

    .detail {
        &-inner {
            width: 100%;
            height: calc(100% - 50px);
            box-sizing: border-box;
            padding-left: 12px;
            padding-right: 34px;
            margin-bottom: 40px;
            overflow: hidden;
            overflow-y: auto;

            h3 {
                line-height: 38px;
                color: #232323;
                text-align: center;
            }

            .other-info {
                height: 30px;
                font-size: 16px;
                color: #696969;
                margin-top: 8px;
                @include flexBox(center);

                span {
                    margin: 0 20px;
                }
            }
        }

        // &--poster {
        //     width: 1046px;
        //     margin: 20px auto 0;
        //     line-height: 30px;
        //     @include flexBox;
        //     align-items: flex-start;

        //     span {
        //         flex-shrink: 0;
        //     }

        //     .img {
        //         width: 80px;
        //         height: 80px;
        //         border-radius: 8px;
        //     }
        // }

        &--abstract {
            width: 1046px;
            margin: 20px auto 0;
            font-size: 16px;
            line-height: 30px;
            text-align: justify;
            color: #434444;
        }

        &--rich {
            width: 1046px;
            margin: 20px auto 0;

            ::v-deep {
                p {
                    font-size: 16px;
                    line-height: 30px;
                    text-align: justify;
                    color: #434444;
                }

                img {
                    display: block;
                    max-width: 100%;
                    border-radius: 10px;
                    margin: 36px auto;
                }
            }
        }

        &--foot {
            height: 62px;
            padding-top: 32px;
            line-height: 24px;
            font-size: 16px;
            color: #1d1d1d;
            border-top: 1px solid #dfe0e6;
            @include flexBox;

            p {
                cursor: pointer;

                &:hover {
                    color: #6c4ecb;
                }

                margin-right: 48px;
            }
        }
    }
}
</style>

<template>
    <section class="article-detail">
        <el-button type="custom_primary" size="small" @click="$router.go(-1)">
            返回
        </el-button>
        <div class="detail-inner">
            <h3 class="bold">{{ detailData.plinf_title }}</h3>
            <div class="other-info pf_medium">
                <span>{{ detailData.create_time }}</span>
                <span>阅读量：{{ detailData.plinf_read_num }}次</span>
                <span>点赞量：{{ detailData.plinf_praise_num }}次</span>
            </div>
            <p class="detail--abstract" v-if="detailData.plinf_precis">摘要：{{ detailData.plinf_precis }}</p>
            <div class="detail--rich" v-html="detailData.plinf_content"></div>
        </div>
    </section>
</template>

<script>
import { articleInfo } from "@api/content";
import { formatFile } from "@utils";
export default {
    name: 'articleDetail',
    computed: {
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        }
    },
    data() {
        return {
            detailData: {},
        }
    },
    created() {
        this.init();
    },
    methods: {
        /** 初始化列表数据 */
        async init() {
            const res = await articleInfo({ plinf_id: this.$route.params.id });
            if (res.status == 200) {
                this.detailData = res.data;
            }
        },

    },
}
</script>